.welcome-page
{
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1em;
}

@media (max-width: 500px)
{
    /*Screen <= 500px*/
    .welcome-page
    {
        display: block;
        padding-top: 3em;
    }
}

.welcome-buttons
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.welcome-button
{
    width: 150px;
    margin: 4px;
}

.welcome-back-button
{
    width: 150px;
    margin-top: 8px;
}

.invisible-button
{
    opacity: 0;
    cursor: default !important;
}

.shlabot-logo
{
    max-width: 260px;
    display: block;
    margin: 1em auto;
}

/* make buttons look more modern */
.btn
{
    border-radius: 0;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #fff;
    padding: 0.75em 1em;
}

.btn-primary
{
    color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover
{
    color: #fff;
    background: #0056b3;
}

.btn-secondary
{
    color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover
{
    color: #fff;
    background: #545b62;
}

.btn-success
{
    color: #28a745;
    border-color: #28a745;
}

.btn-success:hover
{
    color: #fff;
    background: #1e7e34;
}

.btn-success-always-hovered
{
    color: #fff;
    background: #1e7e34;
}

.btn-danger
{
    color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover
{
    color: #fff;
    background: #bd2130;
}

.btn-danger-always-hovered
{
    color: #fff;
    background: #bd2130;
}
