select
{
    cursor: pointer;
}

.location-details-container
{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
}

.location-details-fields-container
{
    margin: auto;
}

@media (min-width: 500px)
{
    .location-details-fields-container
    {
        width: 450px;
    }
}

.location-details-features
{
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.location-details-input-field-group
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.location-details-input-field-label
{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1rem;
}

.location-details-input-field
{
    width: 60% !important;
}

@media (max-width: 500px)
{
    .location-details-input-field
    {
        width: 49% !important;
    }
}

.location-details-features-label
{
    width: 100%;
    margin: auto;
    text-align: center;
    border-top: 1px solid black;
    padding-top: 1rem;
    margin-top: 1rem;
}

.location-details-feature
{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    width: 245px;
    min-height: 245px;
}

.location-details-feature-explanation
{
    font-style: italic;
}

.location-details-buttons
{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.location-details-button
{
    margin-left: 1%;
    margin-right: 1%;
}
