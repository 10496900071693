.donate-description
{
    font-size: 18px;
    color: #34495e;
    text-align: center;
}

.donate-currency-dropdown
{
    max-width: 120px;
}

.donate-flag-symbol
{
    width: 20px;
    margin-right: 10px;
}

.donate-payment-options
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.donate-payment-option
{
    margin-bottom: 3%;
    max-width: 200px;
    width: 100%;
}

.donate-payment-option img /* Image inside the donate-payment-option */
{
    height: 20px;
}
