/* Import fonts - Open Sans and Jura */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Jura:wght@700&display=swap');

:root
{
    --header-height: 60px;
    --footer-height: 40px;
    --form-field-height: 2.71428571em;
}

.app
{
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

/* Set the font family including overrides for semantic UI's default font */
.app,
body,
h1,
h2,
h3,
h4,
h5,
.ui.button,
.ui.text.container,
.ui.header,
.ui.input > input,
.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.steps .step .title,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.menu,
.ui.message .header,
.ui.card > .content > .header,
.ui.cards > .card > .content > .header,
.ui.items > .item > .content > .header,
.ui.statistic > .value,
.ui.statistics .statistic > .value,
.ui.statistic > .label,
.ui.statistics .statistic > .label,
.ui.accordion .title:not(.ui),
.ui.modal > .header,
.ui.popup > .header,
.ui.search > .results .result .title,
.ui.search > .results > .message .header,
.ui.category.search > .results .category > .name
{
    font-family: "Open Sans", arial, sans-serif; /* Font + fallbacks */
}

.app-header
{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: var(--header-height);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    z-index: 1000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.app-title
{
    display: flex;
    align-items: center;
    gap: 0.75em;
    cursor: pointer;
}

.app-title > h1
{
    margin: 0;
    font-family: 'Jura', 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 2.25em;
}

.shla {
    color: #0a53be;
}

.bot {
    color: #0d6efd;
}

.app-icon
{
    height: 2.25em;
}

.app-body
{
    height: calc(100vh - var(--header-height));
    background: #f7f7f7;
    margin-top: var(--header-height);
    overflow-y: scroll;
}

.app-footer
{
    width: 100%;
    bottom: 0;
    left: 0;
    height: var(--footer-height);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-family: 'Open Sans', sans-serif;
    z-index: 9999;
    border-top: 1px solid #e9ecef;
}

/* Date Picker CSS */
.react-datepicker-wrapper
{
    display: block;
}

input.react-datepicker
{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    min-height: var(--form-field-height);
}

.react-datepicker__header button
{
    border: none;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-datepicker__header .form-select
{
    font-size: 1.15em;
    padding: 0 2.25rem 0 .75rem;
    height: 2.5em;
}

.date-picker
{
    margin: 10px;
    display: flex;
    justify-content: center;
}

.year-select, .month-select
{
    width: 5.8em;
}

.clickable
{
    cursor: pointer;
}

.link-text
{
    color: blue !important;
    text-decoration: underline;
    cursor: pointer;
}

.no-select
{
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
