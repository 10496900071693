.weekly-student-form-title
{
    margin-top: 0 !important;
    margin-bottom: 10px;
}

.weekly-age-range-container
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.weekly-age-range-input
{
    width: 40%;
}

.weekly-age-range-separator
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.weekly-guest-input-container
{
    display: flex;
    justify-content: center;
}

.weekly-guest-gender-input-container
{
    display: flex;
}

.clickable-icon
{
    cursor: pointer;
}
