.form-page
{
    max-width: 500px;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    margin: auto;
    padding: 30px 0;
}

@media (max-width: 500px)
{
    /*Screen <= 500px*/
    .form-page
    {
        max-width: 100%;
        margin: 0 1em;
    }
}

.form-loading
{
    /*Centre horizontally and vertically*/
    display: flex;
    flex-direction: column; /*Top to bottom rows*/
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.form-title
{
    text-align: center;
}

.submit-form-button-container
{
    display: flex;
    justify-content: center;
}

.submit-form-button
{
    margin-bottom: 2%;
}

.form-error-msg
{
    color: red;
    min-height: 1.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
}

.form-control
{
    min-height: 2.71428571em;
}

.form-label
{
    font-weight: bold;
}

.form-text
{
    color: black !important;    
}

.react-time-picker__wrapper
{
    border: none !important;
}

::placeholder
{
    color: rgba(191,191,191,.87) !important;
}

.dropdown-toggle, .dropdown-menu
{
    text-align: left;
    width: 100% !important;
}

.dropdown-menu
{
    cursor: pointer;
}

.item
{
    height: 2rem;
}
