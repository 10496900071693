.maintainer-page
{
    text-align: center;
    max-width: 800px;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    padding: 20px 0 30px 0;
    margin: auto;
}

@media (max-width: 500px)
{
    /*Screen <= 500px*/
    .maintainer-page
    {
        max-width: default;
        max-width: 100%;
        margin: 0 2%;
    }
}

.maintainer-page-title
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.maintainer-lists
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 50px;
    width: 100%;
}

.maintainer-list-section
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-evenly;
}
  
.maintainer-list
{
    /* margin-left: 50px;
    margin-right: 50px; */
}

.maintainer-list-title
{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
  
.maintainer-list-content
{
    display: flex;
    flex-direction: column;
    width: 350px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 20px;
}

.maintainer-accordian-with-button-container
{
    display: flex;
    flex-direction: column;
}

.maintainer-accordian-header-with-button
{
    margin-top: 0px;
    width: 100%;
}

.accordion-item
{
    width: 100%;
}

.accordion-body
{
    text-align: left;
}

.maintainer-main-accordian-body
{
    padding-left: 0px;
    padding-right: 0px;
}

.maintainer-date-addition-button, .maintainer-set-graduated-button
{
    width: fit-content;
    margin: auto;
}

.maintainer-registered-host-name
{
    margin-right: 5px;
}

.maintainer-allocated-student-list-item
{
    list-style-type: none;
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.maintainer-allocated-student-name
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    width: 100%;
    cursor: pointer;
    overflow-x: auto;
}

/* Highlight when hovered */
.maintainer-allocated-student-name:hover
{
    background-color: #f1f1f1;
}

.allocation-warnings-card
{
    margin-bottom: 3%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.swal2-html-container
{
    /* Force the dropdown to be visible */
    overflow: visible !important;
    z-index: 9999 !important;
}
