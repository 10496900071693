.maintainer-date-addition
{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.maintainer-date-input-container
{
    display: flex;
    justify-content: center;
    width: 100%;
}

.add-dates-remove-date-button
{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-right: 1%;
}

input.react-datepicker /* Specifically for the input field that has the class react-datepicker */
{
    font-family: inherit;
    font-size: 1.5rem;
    color: #333;
}

.maintainer-holy-day-dropdown
{
    width: 180px !important;
    margin-left: 1%;
    margin-right: 1%;
}

.maintainer-lunch-dinner-dropdown
{
    width: 100px !important;
}

.add-dates-more-options-container
{
    text-align: left;
    padding-left: 1rem;
}

.add-dates-more-options-button
{
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.add-dates-more-options-hidden-container
{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
